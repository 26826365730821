import React from 'react';
import PropTypes from 'prop-types';

import styles from './TabsItemContainer.module.css';

import Title from '../../elements/Title';
import Text from '../../elements/Text';

const TabsItemContainer = ({ item }) => {
  //   <span dangerouslySetInnerHTML={{ __html: title }} />
  const { hierarchyName, name, description, list } = item;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={`${styles.hierarchyName} mt-1`}>{hierarchyName}</div>
        <Title type="big" className="text-center">
          {name}
        </Title>
      </div>
      <div className={styles.bodyContainer}>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Text>
        <div className={styles.listContent}>
          <ul>
            {list.map((o, i) => {
              return (
                <li key={i} className={`${o.featured ? styles.featured : ''}`}>
                  <Text>{o.item}</Text>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

TabsItemContainer.propTypes = {
  item: PropTypes.shape({
    hierarchyName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.string.isRequired,
        featured: PropTypes.bool.isRequired,
      })
    ),
  }),
};

export default TabsItemContainer;
