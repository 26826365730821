import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import LandingHeader from '../components/slides/stealth-selling/LandingHeader';
import Introduction from '../components/slides/stealth-selling/Introduction';
import NeverPushingSales from '../components/slides/stealth-selling/NeverPushingSales';
import StealthSellingSlide from '../components/slides/stealth-selling/StealthSelling';
import SuccessfulLaunch from '../components/slides/stealth-selling/SuccessfulLaunch';
import MoreSales from '../components/slides/stealth-selling/MoreSales';
import EmailsThatSell from '../components/slides/stealth-selling/EmailsThatSell';
import Modules from '../components/slides/stealth-selling/Modules';
import MiddleSection from '../components/slides/stealth-selling/MiddleSection';
import Testimonials from '../components/slides/stealth-selling/Testimonials';
import Pricing from '../components/slides/stealth-selling/Pricing';
import Bonus from '../components/slides/stealth-selling/Bonus';
import Bottom from '../components/slides/stealth-selling/Bottom';

const StealthSelling = () => (
  <Layout noHeader={true}>
    <SEO title="Stealth Selling | Deadline Funnel" />
    <LandingHeader />
    <Introduction />
    <NeverPushingSales />
    <StealthSellingSlide />
    <SuccessfulLaunch />
    <MoreSales />
    <EmailsThatSell />
    <Modules />
    <MiddleSection />
    <Testimonials />
    <Pricing />
    <Bonus />
    <Bottom />
  </Layout>
);

export default StealthSelling;
