import React from 'react';

import styles from './StealthSelling.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Text from '../elements/Text';
import Title from '../elements/Title';

const StealthSelling = () => {
  return (
    <div className={styles.stealthSelling}>
      <Container>
        <Container type="mobile">
          <div className={styles.swiggleContainer}>
            <Image name="swiggle" />
          </div>
          <div className={styles.confettiDesktopContainer4}>
            <Image name="stealth-landing-confetti_desktop_4" />
          </div>

          <div className={styles.textContainer}>
            <Title type="italic">
              My method for writing emails to my list is{' '}
              <br className="br-desktop-and-mobile" /> way different.
            </Title>
            <Title type="italic">I call it...</Title>
            <Title type="bigger">
              {' '}
              <span className={styles.titleContainer}>Stealth Selling</span>
            </Title>
            <Text>
              It’s when you blend teaching and selling so that every single
              email is not only valuable… they ALSO make sales like crazy,
              without having to hard pitch.
            </Text>
            <Text>
              Instead of twisting your reader’s arm, trying to FORCE them to
              buy… Stealth Selling emails gently pull people toward your brand.
              They educate, motivate, and inspire the people on your list.
            </Text>
            <Text>When people are ready to buy, they buy.</Text>
            <Text>
              And they wind up sticking around longer and refunding less,
              because the whole buying decision was THEIR idea.
            </Text>
            <Text>
              You don’t have to bludgeon them over the head with a hundred
              emails… you don’t have to add in a dozen bonuses or extend your
              ‘open cart’ for an extra week or two… and you don’t have to
              pressure your subscribers into buying from you.
            </Text>
            <Text>
              Instead, your subscribers WANT to become customers because they’ll
              see it as the natural next step in your relationship.
            </Text>
            <Text>The funny thing is…</Text>
            <Text>
              When you start using Stealth Selling, you start making MORE sales
              than ever before. And your customers have an all-around better
              experience with your brand.
            </Text>
          </div>
        </Container>
      </Container>
      <div className={`${styles.imagesFooterContainer}`}>
        <div className={`${styles.plantContainer}`}>
          <Image name="plant-2" />
        </div>
      </div>
    </div>
  );
};

export default StealthSelling;
