import React from 'react';

import styles from './MoreSales.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import Text from '../elements/Text';

import WistiaEmbed from '../../../elements/WistiaEmbed';

const MoreSales = () => {
  return (
    <div className={`${styles.moreSales}`}>
      <Container>
        <Container type="mobile">
          <div className={styles.airplaneContainer}>
            <Image name="paper-airplane" />
          </div>
          <div className={styles.videoContainer}>
            <WistiaEmbed type="inline" hashedId="tqg6d97x81" />
          </div>
          <div className={styles.textContainer}>
            <Text>Why was Gigi’s launch such a huge success?</Text>
            <Text>
              Well, she obviously had a great product. And she’d been in
              business for years.
            </Text>
            <Title type="medium">
              But a big part of the reason this launch did so well is because we
              wrote better emails.
            </Title>
            <Text>
              We wrote them according to the principles I teach in my Stealth
              Selling system.
            </Text>
            <Text>And now…</Text>
            <Text>
              I’d like to help YOU use Stealth Selling to make every single
              email you ever write to your list much more powerful.
            </Text>
            <Text>
              Stealth Selling isn’t just about launches. It’s about giving you a
              system for writing emails that people LOVE to read… that sell your
              products every time you hit send… and that build goodwill with
              your subscribers, so they stick around for the long haul.
            </Text>
            <div>
              <div className={styles.highlightContainer}>
                <div>
                  Make More Sales <br className="mobile-br" /> From Your
                  <br className="desktop-br" /> List{' '}
                  <br className="mobile-br" /> Without Morphing{' '}
                  <br className="mobile-br" />
                  Into The <br className="mobile-br" />
                  <br className="desktop-br" />
                  ShamWow Guy
                  <div className={styles.highlightLine}></div>
                </div>
              </div>
              <div className={styles.confettiDesktopContainer5}>
                <Image name="stealth-landing-confetti_desktop_5" />
              </div>
            </div>
            <Text>
              You’ve probably never heard of “Stealth Selling” before reading
              this page. And for good reason - it tends to fly in the face of
              most email marketing advice.
            </Text>
            <Title type="medium">
              You see, a lot of marketers are obsessed with conversions... but
              they neglect the connection.
            </Title>
            <Text>
              They don’t really care about the people on the other end of the
              emails they’re sending.
            </Text>
            <Text>
              All they care about is the bottomline, the almighty dollar...
            </Text>
            <Text>Don’t get me wrong… I’m not against selling.</Text>
            <Text>
              After all, you run a business. You have to sell to make money and
              support yourself and your loved ones.
            </Text>
            <Text>BUT…</Text>
            <Text>
              There is a MUCH better way to do it that doesn’t burn out your
              subscribers with constant hard pitches all the time.
            </Text>
            <Text>
              Stealth Selling is all about selling right from the beginning by
              planting “idea seeds” early and often into your readers’ minds.
              These idea seeds will grow and grow until your customers realize
              your products are the right solution to help them on their
              journey.
            </Text>
            <Text>
              You don’t have to give away mountains of free content. You don’t
              have to turn your emails into infomercials. You don’t have to
              become a carnival barker.
            </Text>
            <Text>
              You don’t have to do any of those things to make more sales and
              build a business you enjoy.
            </Text>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default MoreSales;
