import React, {useState} from 'react';
// import Slider from 'react-slick';
import {graphql, useStaticQuery} from 'gatsby';

import styles from './Testimonials.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Text from '../elements/Text';
import Title from '../elements/Title';

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/stealth-testimonials.md/"}}
      ) {
        edges {
          node {
            frontmatter {
              videos {
                name
                title
                subtitle
                thumbnail
                url
              }
              testimonials {
                name
                position
                web
                quote
                review
                link
                image
              }
            }
          }
        }
      }
    }
  `);

  const {
    videos,
    testimonials,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,

  //   responsive: [
  //     {
  //       breakpoint: 900,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //   ],
  // };

  const [selectedVideo, setSelectedVideo] = useState(videos[0]);

  return (
    <div className={styles.testimonials}>
      <Container>
        <Container type="mobile">
          <div className={styles.confettiDesktopContainer9}>
            <Image name="stealth-landing-confetti_desktop_9" />
          </div>
          <div className={styles.header}>
            <Title type="bigxl">
              What have people said about Stealth Selling?
            </Title>
          </div>
          <div className={styles.textContainer}>
            <Text>
              I could keep going on and on about why I think Stealth Selling is
              so awesome. But I’m sure you wanna hear what some of my students
              had to say about it.
            </Text>
            <Text>
              Here’s what some of my students said about Stealth Selling...
            </Text>
          </div>
          <div>
            <div className={styles.desktopContainer}>
              <div className={styles.desktopImageContainer}>
                <Image name="desktop-with-confetti" />
              </div>
              <iframe
                src={selectedVideo.url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title={selectedVideo.name}
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className={styles.sliderContainer}>
            {/* <Slider {...settings} adaptiveHeight={true}> */}
              {videos.map((video, i) => {
                return (
                  <div
                    className={styles.testimonials__item}
                    onClick={() => setSelectedVideo(video)}
                    aria-hidden="true"
                    key={i}
                  >
                    <div className={styles.testimonials__videoPreview}>
                      <Image name={video.thumbnail} />
                      <Image name="play" className={styles.testimonials__playImage} />
                      <div className={styles.testimonials__playImageMobile} />
                    </div>
                    <div className={styles.testimonials__description}>
                      <div className={styles.testimonials__title}>{video.title}</div>
                      <div className={styles.testimonials__subtitle}>{video.subtitle}</div>
                    </div>
                    {/* <Image name={video.thumbnail} /> */}
                  </div>
                );
              })}
            {/* </Slider> */}
          </div>
          <div className={styles.testimonialsContainer}>
            {testimonials.map((testimonial, i) => {
              const {
                name,
                position,
                web,
                quote,
                review,
                link,
                image,
              } = testimonial;
              return (
                <div className={styles.testimonialContainer} key={i}>
                  <div className={styles.testimonialHeader}>
                    <div className={styles.testimonialImage}>
                      <Image name={image} />
                    </div>
                    <div className={styles.testimonialMetaContainer}>
                      <div className={styles.testimonialName}>{name}</div>
                      <div className={styles.testimonialPosition}>
                        {position}
                      </div>
                      <div className={styles.testimonialWeb}>{web}</div>
                    </div>
                  </div>
                  <div className={styles.testimonialBody}>
                    <div className={styles.testimonialQuote}>{quote}</div>
                    <div
                      className={styles.testimonialReview}
                      dangerouslySetInnerHTML={{__html: review}}
                    />
                  </div>
                  <div className={styles.testimonialFooter}>
                    {link && (
                      <a href={link} rel="nofollow" target="blank">
                        Continue reading
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Testimonials;
