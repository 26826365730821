import React from 'react';

import styles from './SuccessfulLaunch.module.css';

import Container from '../../../blocks/Container';

import Title from '../elements/Title';
import Text from '../elements/Text';

const SuccessfulLaunch = () => {
  return (
    <div className={`${styles.successfulLaunch}`}>
      <Container>
        <Container type="mobile">
          <Title type="underlined">
            <span className={styles.line}>
              {' '}
              Here’s how we <br className="mobile-br" /> used Stealth Selling
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" />
              <br className="mobile-br" /> to help Gigi have a{' '}
              <br className="mobile-br" />
              wildly
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" />
              successful <br className="mobile-br" />
              launch...
            </span>
          </Title>
          <div className={styles.textContainer}>
            <Text>
              Gigi wanted to make as many sales as possible… while also giving
              her subscribers and customers an awesome experience.
            </Text>
            <Text>
              So I had to be real honest with her about the emails she had
              written:
            </Text>
            <Title type="medium">
              “There’s nothing wrong with these emails,” I told her. “They’re
              going to get you sales. But they’re not going to get you the crazy
              numbers you’re hoping for.”
            </Title>
            <Text>
              I rewrote one of the emails for Gigi and sent it to her.
            </Text>
            <Text>The day after, we got on a call and she said:</Text>
            <Text>
              “Jack, this is one of the most spectacular emails I’ve ever read.
              How much is it going to be for you to write all of them?”
            </Text>
            <Text>
              “Woah woah!” I replied. “I have no intention of writing your
              emails. I wasn’t interviewing for the job!”
            </Text>

            <Text>Gigi wouldn’t relent.</Text>
            <Text>As a result…</Text>
            <Text>
              She had seen what was possible and she wanted more of it.
            </Text>
            <Text>And again, I really wanted her to succeed.</Text>
            <Text>So…</Text>
            <Title type="medium">
              I made a deal with Gigi to help write her emails as a means of
              deconstructing and systemizing the process... so I could teach it
              to other course creators like her.
            </Title>
            <Text>
              “You’re on,” she said. And I helped her write the emails.
            </Text>
            <Text>Can you guess what happened next?</Text>
            <Text>
              Gigi wound up having a HUGE launch, massively outselling her
              initial target.
            </Text>
            <Text>She made more sales…</Text>
            <Text>With less stress…</Text>
            <Text>And a lot less headaches…</Text>
            <Text>
              And, on top of that, she got more positive feedback from the
              people on her list than from any promotion she ever ran before.
            </Text>
            <Text>Here’s what she said about it…</Text>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SuccessfulLaunch;
