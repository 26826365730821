import React from 'react';

import styles from './EmailsThatSell.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import Text from '../elements/Text';

const EmailsThatSell = () => {
  return (
    <div className={`${styles.emailsThatSell}`}>
      <Container>
        <Container type="mobile">
          <Title type="underlined">
            <div className={styles.confettiDesktopContainer6}>
              <Image name="stealth-landing-confetti_desktop_6" />
            </div>
            <span className={styles.line}>
              {' '}
              Write Emails That <br className="mobile-br" />
              Sell,
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" />
              <br className="mobile-br" /> That People
              <br className="mobile-br" />
              Enjoy Reading…
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" /> In HALF The Time.
            </span>
          </Title>
          <div className={styles.textContainer}>
            <Text>
              Maybe you can relate to Gigi’s story a bit. Maybe like her, you’re
              incredibly skilled at the thing you do.
            </Text>
            <Text>
              Maybe you’re a coach, a course creator, or a consultant.{' '}
            </Text>
            <Text>
              And maybe you know that you need to write emails to sell…{' '}
              <span className={styles.smallTitle}>
                but you’re just not sure what to write when you sit down in
                front of the computer each morning.
              </span>
            </Text>
            <Text>
              Every time you try, it’s the same blinking cursor taunting you on
              the screen… your block completely locks you up… you know you
              there’s a story inside you… but the words just aren’t coming.
            </Text>
            <Text type="small">
              <span className={styles.smallTitle}>
                And even if they did, you’re not sure how to connect any of it
                to a business purpose that makes sense to your readers.
              </span>
            </Text>
            <Text>
              Maybe like Gigi, you’re legendary at what you do, but just not a
              legendary marketer.
            </Text>
            <Text>And that’s okay.</Text>
            <Text>Because frankly…</Text>
            <Title type="medium">
              You Don’t Need To Be A Legendary Marketer To Write Legendary
              Emails
            </Title>
            <Text>
              All you need to do is to learn the art of Stealth Selling - my
              system that blends teaching and selling together to grow the
              impact of your email marketing.
            </Text>
            <Text>
              It’s the exact same simple ideas and structures I showed Gigi to
              get her emails from average ordinary to ABSOLUTELY EXTRAORDINARY.
            </Text>
            <Text>
              <span className={styles.smallTitle}>
                In fact, it’s so simple.... even my 14-year old daughter was
                able to understand and apply the framework in just a few minutes
                during a drive back home from one of her tennis matches.
              </span>
            </Text>
            <Text>Literally anyone can use this system to:</Text>
          </div>
        </Container>
      </Container>
      <div className={styles.listContainer}>
        <div className={styles.listContent}>
          <ul>
            <li> Increase the perceived value of their content</li>
            <li>Build “know, like and trust” a lot faster</li>
            <li>Demonstrate their authority and expertise</li>
            <li>Be seen as a mentor and friend and</li>
            <li>Sell smoothly without pitching</li>
          </ul>
        </div>
      </div>
      <Container>
        <Container type="mobile">
          <div className={styles.textContainer}>
            <Text>
              Through my work with Gigi, I created the Stealth Selling system so
              I can start teaching it to my coaching students.
            </Text>
            <Text>
              And all the training videos, breakdowns, formulas, and examples I
              shared inside my private program has been turned into a digital
              course you can access immediately… so you can start making more
              sales from your list every time you send an email.
            </Text>
            <div className={styles.confettiDesktopContainer7}>
              <Image name="stealth-landing-confetti_desktop_7" />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default EmailsThatSell;
