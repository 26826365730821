import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import styles from './TabsContainer.module.css';

import Image from '../../../../elements/Image';

import TabsItemContainer from '../TabsItemContainer';

const TabsContainer = ({items, type}) => {
  const options = items.map((item) => item.hierarchyName);

  const selectItem = (option) => {
    return items.filter((i) => i.hierarchyName === option)[0];
  };

  const selectItemCallback = useCallback(
    (option) => {
      return items.filter((i) => i.hierarchyName === option)[0];
    },
    [items]
  )

  const [option, setOption] = useState(options[0]);
  const [item, setItem] = useState(selectItem(option));
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMenuMobile = (option) => {
    setOption(option);
    setMobileMenu(false);
  };

  useEffect(() => {
    setItem(selectItemCallback(option));
  }, [selectItemCallback, option]);

  return (
    <div className={styles.container}>
      <ul className={styles.optionList}>
        {options.map((o, i) => {
          return (
            <li
              key={`item-${o}`}
              onClick={() => setOption(o)}
              className={`option ${o === option ? styles.optionActive : ''} ${
                type === 'light' && o === option ? styles.borderDark : ''
              }`}
              aria-hidden="true"
            >
              {o}
            </li>
          );
        })}
      </ul>

      {mobileMenu ? (
        <ul className={styles.optionList__mobile}>
          {options.map((o, i) => {
            return (
              <li
                aria-hidden="true"
                key={`mobileitem-${o}`}
                onClick={() => handleMenuMobile(o)}
                className={`${styles.option__mobile} ${
                  o === option ? styles.optionActive__mobile : ''
                } ${type === 'light' && o === option ? styles.borderDark : ''}`}
              >
                {o}
              </li>
            );
          })}
        </ul>
      ) : (
        <div
          className={`${styles.optionActive__mobile} ${
            type === 'light' ? styles.borderDark : ''
          }`}
          onClick={() => setMobileMenu(!mobileMenu)}
          aria-hidden="true"
        >
          <div>{option}</div>
          <div style={{width: '12px', marginLeft: '0.6em'}}>
            {type === 'light' ? (
              <Image name="dark-arrow" />
            ) : (
              <Image name="white-arrow" />
            )}
          </div>
        </div>
      )}

      {item && <TabsItemContainer item={item} />}
    </div>
  );
};

TabsContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      hierarchyName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          item: PropTypes.string.isRequired,
          featured: PropTypes.bool.isRequired,
        })
      ),
    })
  ).isRequired,
  type: PropTypes.string,
};

export default TabsContainer;
