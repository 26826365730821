import React from 'react';

import styles from './Pricing.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Text from '../elements/Text';
import Title from '../elements/Title';
import Button from '../elements/Button';

const Pricing = () => {
  return (
    <div className={styles.pricing}>
      <Container>
        <Container type="mobile">
          <div className={styles.confettiDesktopContainer10}>
            <Image name="stealth-landing-confetti_desktop_10" />
          </div>

          <div className={styles.textContainer}>
            <Title type="italic">How much is</Title>
            <Title type="bigger">
              {' '}
              <span className={styles.titleContainer}>Stealth Selling</span>
            </Title>
            <Text>
              If you were to hire a professional copywriter, you’d probably pay
              an arm and a leg. And there’s still a chance they might not
              exactly nail your voice or the vibe of your brand.
            </Text>
            <Text>
              In fact, a lot of pro email copywriters might charge $10,000+ to
              put together an email launch campaign.
            </Text>
            <Text>The fact is…</Text>
            <Text>
              Every business owner should know how to write emails that sell.
            </Text>
            <Text>
              There’s tons of other courses and trainings out there that are
              $1,000 - $2,000+ that teach these concepts.
            </Text>
            <Text>I think that’s a little excessive.</Text>
            <Text>
              I wanted to make this course affordable for any digital product
              creator who wanted to learn how to sell.{' '}
            </Text>
            <Title type="small" className="font-italic text-left">
              I want all my customers to be able to get a huge ROI with the very
              first Stealth Selling email they send to their list.
            </Title>
            <Text>That’s why I made Stealth Selling just…</Text>
            <div className={`${styles.priceWithTextContainer}`}>
              <div className={styles.priceContainer}>
                <Image name="99-price" />
              </div>
              <div>
                <Title name="title-italic" className="text-white text-left">
                  Yup.
                </Title>
                <Title name="title-italic" className="text-white text-left">
                  Just $99.
                </Title>
                <Title name="title-italic" className="text-white text-left">
                  Pretty cool, right?
                </Title>
              </div>
            </div>
            <Text>
              I think this goes without saying: obviously this course is priced
              WAY below what it should be. And sometime soon (I’m not sure
              when), I will be raising the price substantially.
            </Text>
            <Text>So...</Text>
            <Text>
              If you’d like to get Stealth Selling now, just click the button
              below…
            </Text>

            <Button href="https://start.deadlinefunnel.com/stealth-selling" target="blank" type="action">
              GET STEALTH SELLING
            </Button>
          </div>
        </Container>
      </Container>
      <div className={`${styles.imagesFooterContainer}`}>
        <div className={`${styles.manContainer}`}>
          <Image name="man-exclamation" />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
