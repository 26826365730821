import React from 'react';

import styles from './MiddleSection.module.css';

import Container from '../../../blocks/Container';

import Text from '../elements/Text';

const MiddleSection = () => {
  return (
    <div className={styles.middleSection}>
      <Container>
        <Container type="mobile">
          <div className={styles.textContainer}>
            <Text>
              In Stealth Selling, I hand you everything you need on a silver
              platter. I show you a simple system for writing emails that sell
              while building a bond with your subscribers. And I even give you a
              look over my shoulder as I break down successful emails I’ve
              written for some of my clients.
            </Text>
            <Text>
              It teaches you everything you need to know to start writing
              better, more effective emails to your list. I think it’ll be one
              of the most powerful email copywriting trainings you’ve ever
              taken.
            </Text>
            <Text>
              I not only give you the strategies and tactics you need to write
              better emails… I also install a new ‘operating system’ into your
              brain so every time you sit down to write an email you know it’s
              going to sell… and that your readers are going to get a ton of
              value from it.
            </Text>
            <Text>
              You’ll go through this course one time… and for the rest of your
              life, every other email you write will perform better.
            </Text>
            <Text>Think about how powerful that is:</Text>
            <Text>
              Imagine if every single email you wrote made more sales...
            </Text>
            <Text>
              Imagine if every email got more happy replies from your
              subscribers...
            </Text>
            <Text>
              Imagine if people started reaching out, telling you how much your
              emails brighten their day...
            </Text>
            <Text>
              Imagine if you could make more sales on command… without having to
              pressure or ‘hard sell’ the people on your list…
            </Text>
            <Text>It’s not hard to do, when you use Stealth Selling.</Text>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default MiddleSection;
