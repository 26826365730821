import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

import {capitalize} from '../../../../../utils';

const Button = ({type, className, children, href, target}) => (
  <div className={styles.buttonContainer}>
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      className={`${styles.button} ${
        styles[`button${capitalize(type)}`]
      } ${className}`}
    >
      {children}
    </a>
  </div>
);

Button.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
