import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './LandingHeader.module.css';

import Title from '../elements/Title';
import Container from '../../../blocks/Container';
import Logo from '../../../../images/svg/logo.svg';
import Text from '../elements/Text';

const LandingHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/landing-header.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.landingHeader}>
      <Container>
        <Container type="mobile">
          <div className={'row'}>
            <div
              className={`${styles.headerContainer} col-12 d-flex flex-column align-items-center`}
            >
              <div className={styles.logoContainer}>
                <Logo />
              </div>
              <Title>{title}</Title>
              <Text className="text-center">{subtitle}</Text>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default LandingHeader;
