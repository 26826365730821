import React from 'react';

import styles from './NeverPushingSales.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import Text from '../elements/Text';
import HighLightContainer from '../elements/HighLightContainer';

const NeverPushingSales = () => {
  return (
    <div className={`${styles.neverPushingSales}`}>
      <Container>
        <Container type="mobile">
          <Title type="underlined">
            <span className={styles.line}>
              {' '}
              She also noticed <br className="mobile-br" /> that I was never
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" />
              <br className="mobile-br" /> pushing the sale (or{' '}
              <br className="mobile-br" />
              hard pitching)
            </span>
            <span className={styles.line}>
              <br className="desktop-and-tablet-br" /> in{' '}
              <br className="mobile-br" />
              any of my emails.
            </span>
          </Title>
          <div className={styles.textContainer}>
            <Text>
              Despite this, I always had tons of customers and never had to
              worry about sales.
            </Text>
            <Text>One day, she reached out to me and said:</Text>
            <Title type="medium">
              “Hey I’ve got a launch coming up. I think I’ve got all the right
              pieces in place… but I’d really like for you to look over
              everything before I go live.”
            </Title>
            <Text>This was Gigi’s first launch ever.</Text>
            <Text>
              And I really wanted her to succeed, so I decided to get involved
              and look at the emails she had written for her launch. But when I
              did, it was very apparent what was holding Gigi back from making
              this launch a major success.
            </Text>
            <Text>
              You see, like most course creators, Gigi had something amazing she
              wanted to offer her audience.
            </Text>
            <Text>But...</Text>
            <div>
              <HighLightContainer>
                Just like 99% of people with online businesses… marketing wasn’t
                her first language.
              </HighLightContainer>
              <div className={styles.confettiDesktopContainer3}>
                <Image name="stealth-landing-confetti_desktop_3" />
              </div>
            </div>

            <Text>
              If you’re newer to writing emails that sell… you’ve probably been
              bombarded with conflicting pieces of information.
            </Text>
            <Text>As a result…</Text>
            <Text>
              You’ve probably been indoctrinated with a TON of bad advice…
            </Text>
            <Text>
              After all, most of the people selling courses on this stuff have
              never sold anything outside the marketing sphere.
            </Text>
            <Text>It’s incredibly infuriating (and kind of gross).</Text>
            <Text>They’ll tell you things like how…</Text>
          </div>
        </Container>
      </Container>
      <div className={styles.listContainer}>
        <div className={styles.listContent}>
          <ul>
            <li>
              {' '}
              You CAN’T promote to new subscribers, you’ve gotta wait for 7-10
              days before selling…{' '}
            </li>
            <li>
              You’ve gotta “move the freeline” before ever asking for a sale…
            </li>
            <li>
              {' '}
              You’ve gotta teach, teach, teach and give away all your best
              stuff… and then the next day, you’re supposed to turn into The
              ShamWow guy and start hard pitching like crazy.
            </li>
          </ul>
        </div>
      </div>
      <Container>
        <Container type="mobile">
          <div className={styles.textContainer}>
            <Text>Listen, I could go on and on. </Text>
            <Text>
              There’s TONS of bad advice out there. But none of these above
              statements are true.{' '}
            </Text>
            <Text>
              In fact, doing any of these things always creates a HUGE
              disconnect.{' '}
            </Text>
            <Text>That’s because... </Text>
            <Title type="medium">
              Shifting from “pure teaching” to “pure selling” in emails is
              awkward… it turns subscribers off... and in the end, they run off
              with all the information you just gave them for free and wind up
              never buying your stuff.
            </Title>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default NeverPushingSales;
