import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Modules.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import Text from '../elements/Text';
import TabsContainer from '../elements/TabsContainer';

const Modules = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/modules.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              modules {
                hierarchyName
                name
                description
                list {
                  item
                  featured
                }
              }
            }
          }
        }
      }
    }
  `);

  const { modules } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.modules}`}>
      <Container>
        <Container type="mobile">
          <div className={styles.confettiDesktopContainer8}>
            <Image name="stealth-landing-confetti_desktop_8" />
          </div>
          <div className={styles.headerContainer}>
            <div className={styles.titlesContainer}>
              <Title type="italic">Introducing... Stealth Selling</Title>
              <Title type="big">
                A Simple System For Writing Emails That Sell WITHOUT Pitching{' '}
              </Title>
            </div>
            <div className={styles.womanContainer}>
              <Image name="woman-letter" />
            </div>
          </div>

          <div className={styles.textContainer}>
            <Text>
              The moment you join, you’ll instantly get access to all the
              modules and bonus trainings where I share with you a clear,
              step-by-step breakdown of my complete Stealth Selling methodology
              so you can start sending better emails and make more sales.
            </Text>
            <Text>
              Stealth Selling features over 8 hours of video instruction plus
              dozens of real-life email examples from my students. You’ll also
              get access to my best email formulas and exercises for generating
              dozens of email ideas in a matter of minutes.
            </Text>
            <Text>
              It’s a compact course on email copywriting that’ll turn you into a
              pro in no-time… even if writing copy isn’t your thing.
            </Text>
            <Text>
              Here’s a sneak peek of what you’ll learn inside Stealth Selling...
            </Text>
          </div>
          <TabsContainer items={modules} />
        </Container>
      </Container>
    </div>
  );
};

export default Modules;
