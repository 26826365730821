import React from 'react';

import styles from './Bottom.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import Text from '../elements/Text';
import HighLightContainer from '../elements/HighLightContainer';
import Button from '../elements/Button';

const Bottom = () => {
  return (
    <div className={`${styles.bottom}`}>
      <Container>
        <Container type="mobile">
          <Title type="underlined">
            <span className={styles.line}>
              Is there a Money-Back <br className="desktop-and-tablet-br" />
            </span>
            <span className={styles.line}>Guarantee?</span>
            <div className={styles.swiggleContainer}>
              <Image name="swiggle_2" />
            </div>
          </Title>
          <div className={styles.textContainer}>
            <Text>
              I am 100% confident that Stealth Selling will be your favorite
              email copywriting course. I believe if you go through it and use
              the simple techniques I show you… you’ll start making more sales
              than ever before. And the engagement and love you’re getting from
              your list will be off the charts.
            </Text>
            <Text>In fact…</Text>
            <Text>
              I’m so confident that I’m willing to put my money where my mouth
              is.
            </Text>
            <Text>
              Get Stealth Selling today. Try it out for a full 30-days. Go
              through all the modules. Test out a few Stealth Selling email
              formulas on your list. Watch the bonus trainings and implement all
              those bonus strategies and techniques.
            </Text>
            <Text>
              If after 30 days, you don’t think Stealth Selling was worth it,
              then send us a message and we'll give you a full refund.
            </Text>
            <Text>No hassles, no questions asked.</Text>
            <div>
              <HighLightContainer>
                Why You Should <br className="desktop-br" /> Get Stealth Selling
                Right Now
                <div className={styles.confettiDesktopContainer12}>
                  <Image name="stealth-landing-confetti_desktop_12" />
                </div>
              </HighLightContainer>
            </div>
            <Text>Obviously you have a choice to make right now.</Text>
            <Text>
              You have an online business. You have an email list. You have
              products and services that people want.
            </Text>
            <Text>This is the ultimate form of leverage.</Text>
            <Text>It’s a recipe for true freedom.</Text>
            <Text>
              You have the opportunity to build an amazing business with
              customers who love you and love your products and services.
            </Text>
            <Text>
              The only thing missing is an email system that can nurture those
              relationships and turn more prospects into customers, like
              clockwork.
            </Text>
            <Text>
              The only way that’s ever going to happen is if you learn how to
              communicate in a way that builds that bond with your list.
            </Text>
            <Text>
              The easiest way to make that happen is with my Stealth Selling
              course.
            </Text>
            <Text>
              I take you by the hand and show you everything you need.
            </Text>
            <Text>Let’s make it happen.</Text>
            <Text>Get Stealth Selling today...</Text>
            <div className={styles.jackSignatureContainer}>
              <Image name="jack-signature" />
            </div>
            <Button href="https://start.deadlinefunnel.com/stealth-selling" type="action" target="blank">
              Let’s Do This
            </Button>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Bottom;
