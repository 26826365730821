import React from 'react';

import {graphql, useStaticQuery} from 'gatsby';

import styles from './Bonus.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Title from '../elements/Title';
import TabsContainer from '../elements/TabsContainer';
import Button from '../elements/Button';

const Bonus = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/bonuses.md/"}}) {
        edges {
          node {
            frontmatter {
              bonuses {
                hierarchyName
                name
                description
                list {
                  item
                  featured
                }
              }
            }
          }
        }
      }
    }
  `);

  const {bonuses} = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={`${styles.bonus}`}>
      <Container>
        <Container type="mobile">
          <div className={styles.titleContainer}>
            <Title type="underlined">
              When You Get Stealth Selling <br className="desktop-br" />
              Today , I’m Going To Give You <br className="desktop-br" /> FOUR
              Bonuses
              <div className={styles.confettiDesktopContainer11}>
                <Image name="stealth-landing-confetti_desktop_11" />
              </div>
            </Title>
          </div>
          <div className={styles.bodyContainer}>
            <TabsContainer items={bonuses} type="light" />
          </div>
          <div className={styles.footerContainer}>
            <Button href="https://start.deadlinefunnel.com/stealth-selling" target="blank" type="action">
              GET STEALTH SELLING
            </Button>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Bonus;
