import React from 'react';

import styles from './Introduction.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import Text from '../elements/Text';
import Title from '../elements/Title';

const Introduction = () => {
  return (
    <div className={`${styles.introduction}`}>
      <Container>
        <Container type="mobile">
          <div className={`${styles.confettiDesktopContainer1}`}>
            <Image name="stealth-landing-confetti_desktop_1" />
          </div>
          <div className={`${styles.confettiMobileContainer1}`}>
            <Image name="stealth-landing-confetti_mobile_1" />
          </div>
          <div className={`row`}>
            <div className={`col-md-7 ${styles.textContainer}`}>
              <Title type="italic">Dear friend, </Title>
              <Title type="italic">
                If you want to make more sales from email (without feeling like
                a sleazy internet marketer), then you’re going to love what I’m
                about to show you...
              </Title>
              <Title type="medium">
                My name is Jack Born and I’m the Founder of Deadline Funnel.
              </Title>
              <Text>
                I'm best known for helping online course creators & eCommerce
                brands reach the biggest audience they can and to maximize sales
                from their marketing campaigns… a big part of which involves
                using email marketing.
              </Text>
              <Text>
                A few years ago, I started a small coaching group where I taught
                and documented all the systems and processes I use when writing
                emails in my own business.
              </Text>
            </div>
            <div className={`col-md-5  ${styles.imageContent1}`}>
              <Image name="jack-surfing" />
            </div>
          </div>

          <div className={styles.highlightContainer}>
            <div className={`${styles.confettiDesktopContainer2}`}>
              <Image name="stealth-landing-confetti_desktop_2" />
            </div>
            <div className={`${styles.confettiMobileContainer2}`}>
              <Image name="stealth-landing-confetti_mobile_2" />
            </div>
            <Title type="big" className="text-white">
              Through this, I ended up meeting an absolute legend in the tennis
              world.
            </Title>
          </div>
          <div className="row mt-sm-5 mt-xs-5">
            <div className={`col-md-5`}>
              {' '}
              <div>
                <Image name="jack-and-gigi" />
              </div>
            </div>
            <div className={`col-md-7 ${styles.textContainer} pl-md-5 `}>
              <Text>
                And when I say legend, I mean she’s literally in the Tennis Hall
                of Fame. She’s won 17 Grand Slams in tournaments like Wimbleton,
                The French Open and The Australian Open. She’s also won several
                Gold Olympic medals.
              </Text>
              <Title type="medium">Her name is Gigi Fernández. </Title>
              <Text>
                Gigi has an online business teaching tennis. She had been
                referred over to me by one of my coaching students.
              </Text>
              <Text>
                She had also been on my list for a while… and had noticed that I
                was always sharing short, simple stories from my life.
              </Text>
            </div>
          </div>
        </Container>
      </Container>
      <div className={`${styles.imagesFooterContainer}`}>
        <div className={`${styles.plantContainer}`}>
          <Image name="plant-1" />
        </div>
        <div className={`${styles.blueArrowContainer}`}>
          <Image name="blue-arrow" />
        </div>
        <div className={`${styles.manReadingContainer}`}>
          <Image name="man-reading" />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
