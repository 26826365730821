import React from 'react';

import styles from './HighLightContainer.module.css';
import PropTypes from 'prop-types';

const HighlightContainer = ({ children }) => {
  return (
    <div className={`${styles.highlightContainer} ${styles.verticalBar} `}>
      <div>{children}</div>
    </div>
  );
};

HighlightContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HighlightContainer;
